-
<template>
  <div class="landing">
    <v-container class="blog-list">
      <v-row>
        <div class="blog-list__title">Корзина</div>
      </v-row>
      <v-row v-if="!objBasket.length">
        <p>
          В корзине нет товаров!
          <button @click="addToBasket">Добавить тестовый</button>
        </p>
      </v-row>
      <v-row> </v-row>
    </v-container>
  </div>
</template>

<script>
import * as names from "../store/names";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TheBasketPage",
  created() {
    this.getBasket();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      objBasket: names.OBJ_BASKET,
    }),
  },
  methods: {
    ...mapActions({
      getBasket: names.ACTION_GET_BASKET,
      addTest: names.ACTION_ADD_BASKET,
    }),

    addToBasket() {
      this.addTest({ service_id: 39 });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
}
</style>
